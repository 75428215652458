<template>
  <section class="instagram-slider">
    <div ref="constrain" class="constrain">
      <h3 v-text="payload.title" />
    </div>
    <Swiper
      v-if="feed != null"
      :breakpoints="swiperBreakpoints"
      :slides-per-view="1.5"
      :space-between="20"
      :slides-offset-before="slidesOffset"
      :slides-offset-after="slidesOffset"
    >
      <SwiperSlide
        v-for="post in feed"
        :key="post.id"
      >
        <Go :to="post.permalink" class="no-underline">
          <div class="aspect a11 mb-4">
            <img
              ref="image"
              :src="post.media_url"
              :alt="post.caption"
              loading="lazy"
            >
          </div>
          <div class="text-sm whitespace-pre-line">
            {{ post.caption }}
          </div>
        </Go>
      </SwiperSlide>
    </Swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref } from 'vue';
import fetch from 'node-fetch';
import { useStore } from 'vuex';
import Image from '@/components/utils/Image.vue';
import { useWindowSize } from '@/composables/observer';

export default {
  components: { Image, Swiper, SwiperSlide },
  props: { payload: { type: Object, default: Object } },
  setup() {
    const store = useStore();
    const feed = ref(null);
    const error = ref(null);
    const slidesOffset = ref(0);
    const swiperBreakpoints = ref({
      640: {
        slidesPerView: 2.5,
      },
      920: {
        slidesPerView: 3.5,
      },
    });

    const calculateOffset = () => {
      const constrainProps = window.getComputedStyle(document.querySelector('.constrain'));
      const marginLeft = parseFloat(
        constrainProps.getPropertyValue('margin-left').replace('px', ''),
      );
      const paddingLeft = parseFloat(
        constrainProps.getPropertyValue('padding-left').replace('px', ''),
      );
      slidesOffset.value = marginLeft + paddingLeft;
    };

    useWindowSize(calculateOffset);
    calculateOffset();

    fetch(`${store.state.router.base}/de/ajax/instagram?feed`)
      .then((data) => data.json())
      .then((data) => {
        feed.value = data.feed;
      })
      .catch((e) => {
        error.value = e;
      });

    return {
      feed,
      error,
      slidesOffset,
      swiperBreakpoints,
    };
  },
};
</script>
